import _ from "lodash";

export default function handlerFactory(eventType) {
  let handlerName = `${eventType}Handler`;
  return {
    inserted(el, binding) {
      let bindingValue = binding.value;
      let handler = _.isObject(bindingValue) && _.isFunction(bindingValue.handler) ?
        bindingValue.handler :
        _.isFunction(bindingValue) ? bindingValue : () => console.log(`on ${eventType} handler was set`);
      let wait = bindingValue && _.isNumber(bindingValue.wait) && bindingValue.wait ? bindingValue.wait : 0;
      let handlerWrapper = function(evt) {
        handler(evt, el);
      };
      handlerWrapper = wait ? _.debounce(handlerWrapper, wait) : handlerWrapper;
      el[handlerName] = handlerWrapper;
      window.addEventListener(eventType, handlerWrapper);
    },
    unbind(el) {
      window.removeEventListener(eventType, el[handlerName]);
    }
  }
}
