import Vuex from 'vuex';

const createStore = () => {
  return new Vuex.Store({
    state: {
      locale: process.env.buildLocale,
      isMobile: false,
      currentScreenWidth: null
    },
    mutations: {
      setIsMobile(state, isMobile) {
        state.isMobile = isMobile;
      },
      setCurrentScreenWidth(state, currentScreenWidth) {
        state.currentScreenWidth = currentScreenWidth;
      }
    },
    getters: {
      getLocale: state => state.locale,
      getIsMobile: state => state.isMobile,
      getCurrentScreenWidth: state => state.currentScreenWidth
    }
  });
};

export default createStore;
