import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '~/locales/en.json'
import ru from '~/locales/ru.json'

Vue.use(VueI18n);

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'en',
    messages: {
      en, ru
    }
  })
}
